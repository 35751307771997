@import './variables.scss';
@import './colors.scss';

@mixin flex($direction: column, $justify-content: center, $align-items: center) {
	display: flex;
	flex-direction: $direction;
	justify-content: $justify-content;
	align-items: $align-items;
}

@mixin transition($element: all, $time: $transition-time, $effect: $transition-effect) {
	transition: $element $time $effect;
}

@mixin focus($width: 2px) {
	&:focus {
		outline: none;
		border: 0;
	}

	&:focus-visible {
		box-shadow: inset 0 0 0 $width $primary-color;
		border: 0;
	}
}

@mixin hover {
	&:hover {
		color: $primary-color;
		@include transition($element: color);
	}
}
@mixin visited {
	&:hover {
		color: $text-dark;
	}
}

@mixin breakpoint($deviceSize) {
	@media screen and (min-width: map-get($breakpoints, $deviceSize)) {
		@content;
	}
}

@mixin cursor() {
	cursor: url($cursor-on-hover-path), auto;
}
