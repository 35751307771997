$font-normal: 400;
$font-bold: 600;
$font-family: 'Montserrat', sans-serif;

$transition-effect: ease-in-out;
$transition-time: 0.5s;

$header-height: 5rem;
$header-height-xs: 3rem;
$footer-height: $header-height;
$footer-height-xs: $header-height-xs;

$container-max-width: 1600px;
$container-s-max-width: 768px;

$spacing-basic: 8px;

$padding-basic: $spacing-basic;
$padding-wrapper: $padding-basic * 6;
$padding-wrapper-xs: $padding-basic;

$border-radius: 40px;

$breakpoints: (
	small: 480px,
	medium: 768px,
	large: 1024px
);

$cursor-on-hover-path: './../images/template/arrow.svg';
