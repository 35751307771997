$primary-color: #f24f4b;
$primary-light: #fdfdfd;
$primary-dark: #0f0f0f;

$secondary-color: $primary-dark;

$grey-light: #e2e2e2;
$grey-medium: #999999;

$text-dark: $primary-dark;
$text-light: $primary-light;

$success: #9bf24b;
$danger: #ef201b;
