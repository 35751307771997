@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600');
@import 'colors.scss';
@import 'variables.scss';
@import 'mixins.scss';

html {
	font-size: 112.5%;
}

body {
	background-color: $primary-light;
	font-family: 'Montserrat', sans-serif;
	font-weight: $font-normal;
	line-height: 1.75;
	color: $text-dark;
}

p {
	margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 1.38rem 0 1.38rem;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	line-height: 1.3;

	@include breakpoint(large) {
		margin: 3rem 0 1.38rem;
	}
}

h1 {
	font-size: 2.488rem;
}

h2 {
	font-size: 2.074rem;
}

h3 {
	font-size: 1.728rem;
}

h4 {
	font-size: 1.44rem;
	line-height: 1.45;
}

h5 {
	font-size: 1.2rem;
}

small,
.text_small {
	font-size: 0.833rem;
}
